let options = {
	root: null,
	threshold: 0.6
};

let observer = new IntersectionObserver(entryInView, options);

let objects = document.querySelectorAll('[data-reveal="fadeIn"]');

objects.forEach(object  => {
	observer.observe(object);
});

function entryInView(entries) {
	entries.forEach(entry => {
		if(entry.isIntersecting) {
			entry.target.style.opacity = '1';
			entry.target.style.transform = 'translateY(0)';
		}
	});
}